/** @format */

import { withStyles } from "@material-ui/core/styles";
import { Backdrop, Box, CardContent, Typography } from "@material-ui/core";
import { useAtomValue } from "jotai/utils";
import { stakedAtomFamily, withdrawableAtomFamily } from "@atoms/balance";
import defaultTheme from "@utils/theme";
import ConnectButton from "../ConnectButton";
import CardSection from "./CardSection";
import Pending from "./Pending";
import Stats from "./Stats";
import Earned from "./Earned";
import AmountStake from "./AmountStake";
import { useMemo } from "react";
import { useAccount } from "wagmi";

const StyledContent = withStyles(() => ({
  root: {
    padding: 0,
    backgroundColor: "transparent",
  },
}))(CardContent);
const StyledBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    fontSize: 22,
    textAlign: "center",
    backgroundColor: "#a3a3aef1",
    width: "100%",
    margin: 0,
    borderRadius: 20,
  },
})(Backdrop);
export default function StakeCard({
  token,
  bg,
  color = defaultTheme.palette.primary.main,
  cardTitle,
  desc,
  logo,
  links,
  open = true,
  disabledActions,
  para,
  pendingText,
  ...props
}) {
  const { isConnected } = useAccount();
  const staked = useAtomValue(stakedAtomFamily(token));
  const withdrawable = useAtomValue(withdrawableAtomFamily(token));

  const cardEnabled = useMemo(() => {
    return true;
  }, [staked, token, withdrawable]);

  if (!cardEnabled) {
    return null;
  }

  return (
    <div className='card_main'>
      <h3 className='text-xl md:text-3xl flex justify-center  font-semibold bg-gradient-to-br  from-[#27252e] to-[#9d9d9e] text-transparent bg-clip-text font-head text-center'>
        {cardTitle}
      </h3>
      <p className='text-center font-para my-3'>{para}</p>
      <div className='ref m-auto my-3 font-para'>{desc}</div>
      <StyledContent>
        <Stats token={token} />
      </StyledContent>
      <div
        style={{
          position: open ? undefined : "relative",
        }}>
        <StyledContent>
          <Earned token={token} />
          {isConnected ? (
            <AmountStake
              logo={logo}
              token={token}
              disabledActions={disabledActions}
            />
          ) : (
            <CardSection>
              <ConnectButton fullWidth rounded size='large' />
            </CardSection>
          )}
        </StyledContent>

        {!open && (
          <StyledBackdrop open>
            <Box>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='30'
                fill='currentColor'
                className='bi bi-lock-fill m-auto'
                viewBox='0 0 16 16'>
                <path d='M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2' />
              </svg>
              <Typography variant='h6' display='block'>
                {pendingText}
              </Typography>
            </Box>
          </StyledBackdrop>
        )}
      </div>
    </div>
  );
}
