/** @format */

import { BigNumber } from "ethers";

export enum Action {
  Stake = 1,
  Unstake,
}

export enum Token {
  // PHB = "PHB",
  JIT = "Y24",
  JIT_BNB_LP = "Y24 ",
  JIT_Y24_LP = "Y24  ",
  JIT_Y24_BUSD_LP = "Y24   ",
  // JIT_BNB_LP_DEPRECATED = "JIT-BNB LP Deprecated",
  // JIT_BNB_LP_LEGACY = "JIT-BNB LP Legacy",
}

export const TokenName = {
  [Token.JIT]: "Y24",
  [Token.JIT_BNB_LP]: "Y24 ",
  [Token.JIT_Y24_LP]: "Y24  ",
  [Token.JIT_Y24_BUSD_LP]: "Y24   ",
};

export const TokenShortName = {
  [Token.JIT]: "Y24",
  [Token.JIT_BNB_LP]: "Y24 ",
  [Token.JIT_Y24_LP]: "Y24  ",
  [Token.JIT_Y24_BUSD_LP]: "Y24   ",
};

declare global {
  type ActionEnum = Action;
  type TokenEnum = Token;
}

export const CHAIN_NAME_MAP: {
  [chain: number]: string;
} = {
  56: "BSC Mainnet",
  97: "BSC Testnet",
};

export const TOKEN_ADDRESS: {
  [chain: number]: {
    [t in Token]: string;
  };
} = {
  56: {
    [Token.JIT]: "0x652000ba3c230d83279AEC84A49d41d706AFB0F1",
    [Token.JIT_BNB_LP]: "0x652000ba3c230d83279AEC84A49d41d706AFB0F1",
    [Token.JIT_Y24_LP]: "0x652000ba3c230d83279AEC84A49d41d706AFB0F1",
    [Token.JIT_Y24_BUSD_LP]: "0x652000ba3c230d83279AEC84A49d41d706AFB0F1",
  },
  97: {
    [Token.JIT]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
    [Token.JIT_BNB_LP]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
    [Token.JIT_Y24_LP]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
    [Token.JIT_Y24_BUSD_LP]: "0x27b9658B2Fb1c9A2a0747cE17Cb945e009591127",
  },
  1337: {
    [Token.JIT]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_BNB_LP]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_Y24_LP]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_Y24_BUSD_LP]: "0x00000000000000000000000000000000000000000",
  },
};

// staking contract
export const STAKING_CONTRACT_ADDRESS: {
  [chain: number]: {
    [t in Token]: string;
  };
} = {
  56: {
    [Token.JIT]: "0x8aCE17bAadBbAfb8178330d4C87C224a08826520",
    [Token.JIT_BNB_LP]: "0x8aCE17bAadBbAfb8178330d4C87C224a08826520",
    [Token.JIT_Y24_LP]: "0xe0Ceee33e1CE1EF4EA322B50D55d99E714B7BB6d",
    [Token.JIT_Y24_BUSD_LP]: "0xe0Ceee33e1CE1EF4EA322B50D55d99E714B7BB6d",
  },
  97: {
    [Token.JIT]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
    [Token.JIT_BNB_LP]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
    [Token.JIT_Y24_LP]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
    [Token.JIT_Y24_BUSD_LP]: "0x41c397d01b2226FbB5Bb0e776aee28eA32a37690",
  },
  1337: {
    [Token.JIT]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_BNB_LP]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_Y24_LP]: "0x00000000000000000000000000000000000000000",
    [Token.JIT_Y24_BUSD_LP]: "0x00000000000000000000000000000000000000000",
  },
};

// const EnvChainId = parseInt(process.env.REACT_APP_CHAIN_ID);

export const ChainId = 56; //[56, 97].indexOf(EnvChainId) > -1 ? EnvChainId : 97;
export const ChainName = CHAIN_NAME_MAP[ChainId];
export const TokenAddresses = TOKEN_ADDRESS[ChainId];
export const StakingAddresses = STAKING_CONTRACT_ADDRESS[ChainId];

// BSC 3 seconds per block
export const BSC_BLOCK_TIME = 3;

// BSC Blocks per year
export const BLOCKS_PER_YEAR = BigNumber.from(
  (60 / BSC_BLOCK_TIME) * 60 * 24 * 365,
); // 10512000

export const DEPRECATED_TOKENS = [
  // Token.JIT_BNB_LP_DEPRECATED,
  // Token.JIT_BNB_LP_LEGACY,
];
