/** @format */

import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAtomValue } from "jotai/utils";
import { cardContent } from "@utils/theme/common";
import { Token, TokenShortName } from "@utils/constants";
import { getFullDisplayBalance, formatNumber } from "@utils/formatters";
import { getApy } from "@utils/apy";
import { tokenStatAtomFamily } from "@atoms/stat";
import { useMemo } from "react";
import { tokenPriceAtomFamily } from "@/atoms/price";

const useStyles = makeStyles({
  root: {
    padding: cardContent.padding,
  },
  item: {
    padding: "4px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  label: {
    fontSize: 14,
    color: "#000",
    fontWeight: 600,
  },
  apy: {
    fontSize: 14,
    fontWeight: 500,
    color: "#000",
  },
  total: {
    fontFamily: "Poppins",
    fontSize: 14, 
    color: "#000",
    fontWeight: 600,
  },
});

export default function Stats({ token }) {
  const classes = useStyles();

  const JITPrice = useAtomValue(tokenPriceAtomFamily(Token.JIT));
  const stakeTokenPrice = useAtomValue(tokenPriceAtomFamily(token));
  const { total, rewardsPerBlock, isRoundActive } = useAtomValue(
    tokenStatAtomFamily(token),
  );

  const apy = useMemo(() => {
    // console.log('isRoundActive',isRoundActive)
    if (!isRoundActive) {
      return 0;
    }

    if (token === Token.JIT) {
      return getApy(1, 1, total, rewardsPerBlock);
    }

    return getApy(stakeTokenPrice, JITPrice, total, rewardsPerBlock);
  }, [isRoundActive, token, stakeTokenPrice, JITPrice, total, rewardsPerBlock]);

  return (
    <Box className={classes.root}>
      <div className={classes.item}>
        <h3 className='text-sm mt-3 font-bold leading-tight bg-gradient-to-br  from-[#27252e] to-[#9d9d9e] text-transparent bg-clip-text  font-head'>
          DPY
        </h3>
        <h3 className='text-xs  mt-3  font-bold leading-tight   font-head'>
          {/* {apy ? `${formatNumber(apy/365)} %` : "232.1 %"} */}
          {Token.JIT === "Y24" && apy
            ? `0.82%`
            : // ? `465 %`
            token === Token.JIT_BNB_LP
            ? "1.23%"
            : token === Token.JIT_Y24_BUSD_LP
            ? "1.91%"
            : token === Token.JIT_Y24_LP
            ? "1.91%"
            : // : `${formatNumber(apy / 365)} %`}
              `0.82%`}
        </h3>
      </div>
      <div className={classes.item}>
        <h3 className='text-sm mt-3 font-bold leading-tight bg-gradient-to-br  from-[#27252e] to-[#9d9d9e] text-transparent bg-clip-text font-head'>
          APY
        </h3>
        <h3 className='text-xs  mt-3  font-bold leading-tight   font-head'>
          {/* {apy ? `${formatNumber(apy)} %` :"847,944 %"} */}
          {Token.JIT === "Y24" && apy
            ? `300%`
            : // ? `465 %`
            token === Token.JIT_BNB_LP
            ? "450%"
            : token === Token.JIT_Y24_BUSD_LP
            ? "700%"
            : token === Token.JIT_Y24_LP
            ? "700%"
            : `300%`}{" "}
          {/* : `${formatNumber(apy)} %`}{" "}  */}
        </h3>
      </div>
      <div className={classes.item}>
        <h3 className='text-sm mt-3 font-bold leading-tight bg-gradient-to-br  from-[#27252e] to-[#9d9d9e] text-transparent bg-clip-text  font-head'>
          Total Staked
        </h3>
        <h3 className='text-xs  mt-3  font-bold leading-tight   font-head'>
          {/* {`0.00 ${TokenShortName[token]}`} */}

          {Token.JIT != "Y24"
            ? `0 ${TokenShortName[token]}`
            : token == Token.JIT_BNB_LP
            ? `${getFullDisplayBalance(total)} ${TokenShortName[token]}`
            : token == Token.JIT_Y24_BUSD_LP
            ?  `${getFullDisplayBalance(total)} ${TokenShortName[token]}`
            : token == Token.JIT_Y24_LP
            ? `${getFullDisplayBalance(total)} ${TokenShortName[token]}`
            : `0 ${TokenShortName[token]}`}
        </h3>
      </div>
    </Box>
  );
}
