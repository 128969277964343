/** @format */

import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import useRequest from "@ahooksjs/use-request";
import StakeCard from "@components/StakeCard";
import { Token } from "@utils/constants";
import useFetchStats from "@hooks/useFetchStats";
import useFetchState from "@hooks/useFetchState";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
const useStyles = makeStyles({
  container: {
    padding: "6vh 24px 24px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative",
    overflow: "hidden",
  },
  card: {
    margin: "20px !important",
  },
});

const cards = [
  {
    token: Token.JIT,
    bg: "./assets/bgs/y24.png",
    color: "#fff",
    cardTitle: "Low Performer",
    desc: <>Adjustable</>,
    para: "Stake Y24 and earn  300% flexible reward",
    open: false,
    pendingText: (
      <>
        The Pool will be live once <br />
        <strong>other two staking pools filled</strong>
        <br />
        <small>No max wallet limit - 1,500,000 reward in Y24</small>
      </>
    ),
  },
  {
    token: Token.JIT_BNB_LP,
    bg: "./assets/bgs/y24.png",
    color: "#fff",
    cardTitle: "Mid Performer ",
    desc: <>7 Days</>,
    para: "Stake Y24 tokens and earn fix 450% reward",
    open: true,
    pendingText: (
      <>
        The Pool will be live at <br />
        <strong>16:15 UTC after PCS listing!</strong>
        <br />
        <small>1000 max wallet - 1,000,000 reward in Y24</small>
      </>
    ),
  },
  {
    token: Token.JIT_Y24_LP,
    bg: "./assets/bgs/y24.png",
    color: "#fff",
    cardTitle: "High Performer",
    desc: <>30 Days</>,
    para: "Stake Y24 tokens and earn fix 700% reward",
    open: true,
    pendingText: (
      <>
        The Pool will be live at <br />
        <strong>18:30 UTC!</strong>
        <br />
        <small>2000 max wallet - 2,000,000 reward in Y24</small>
      </>
    ),
  },
];

export default function Home() {
  const classes = useStyles();
  const { isConnected } = useAccount();

  const fetchStats = useFetchStats();
  const fetchState = useFetchState();

  useRequest(fetchStats, {
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });

  const { run, cancel } = useRequest(fetchState, {
    manual: true,
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });
  const { chain } = useNetwork();
  const { isLoading, pendingChainId, switchNetwork } = useSwitchNetwork();
  useEffect(() => {
    if (isConnected && chain?.id === 56) {
      run();
    } else {
      // switchNetwork?.("56");
      cancel();
    }
  }, [cancel, isConnected, run]);

  return (
    <div className={classes.container}>
      {cards.map(card => (
        <StakeCard key={card.token} {...card} className={classes.card} />
      ))}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        version='1.1'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        // xmlns:svgjs='http://svgjs.dev/svgjs'
        className=' absolute bottom-0 w-full -z-50'
        width={1440}
        height={250}
        preserveAspectRatio='none'
        viewBox='0 0 1440 250'>
        <g mask='url("#SvgjsMask1006")' fill='none'>
          <rect
            width={1440}
            height={250}
            x={0}
            y={0}
            fill='rgba(255, 255, 255, 0)'
          />
          <path
            d='M26 250L276 0L364.5 0L114.5 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M278.6 250L528.6 0L782.1 0L532.1 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M504.20000000000005 250L754.2 0L884.7 0L634.7 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M715.8000000000001 250L965.8000000000001 0L1261.3000000000002 0L1011.3000000000001 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M1401 250L1151 0L1092.5 0L1342.5 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1178.4 250L928.4000000000001 0L700.4000000000001 0L950.4000000000001 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M936.8 250L686.8 0L418.29999999999995 0L668.3 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M710.1999999999999 250L460.19999999999993 0L143.69999999999993 0L393.69999999999993 250z'
            fill='url("#SvgjsLinearGradient1008")'
          />
          <path
            d='M1304.192611426263 250L1440 114.19261142626311L1440 250z'
            fill='url("#SvgjsLinearGradient1007")'
          />
          <path
            d='M0 250L135.8073885737369 250L 0 114.19261142626311z'
            fill='url("#SvgjsLinearGradient1008")'
          />
        </g>
        <defs>
          <mask id='SvgjsMask1006'>
            <rect width={1440} height={250} fill='#ffffff' />
          </mask>
          <linearGradient
            x1='0%'
            y1='100%'
            x2='100%'
            y2='0%'
            id='SvgjsLinearGradient1007'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
          <linearGradient
            x1='100%'
            y1='100%'
            x2='0%'
            y2='0%'
            id='SvgjsLinearGradient1008'>
            <stop stopColor='rgba(107, 110, 118, 0.2)' offset={0} />
            <stop
              stopOpacity={0}
              stopColor='rgba(107, 110, 118, 0.2)'
              offset='0.66'
            />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
