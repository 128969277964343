/** @format */

import React from "react";

const Header = () => {
  return (
    <div>
      <header className=' sticky top-0  '>
        {/* Navigaiton Bar */}
        <nav className='flex items-center py-8 px-14 justify-between relative'>
          <div className='flex items-center'>
            <a href='https://y24.io/'>
              <img src='assets/logo-hr.png' className='w-56 block' alt='' />
            </a>
            {/* <h1 className='text-xl md:text-4xl font-head font-black text-gray-700 ml-3 bg-gradient-to-br  from-[#57546C] to-[#b4b4b4] text-transparent bg-clip-text'>
              Yield <span>24</span>
            </h1> */}
          </div>
          <div className='flex items-center'>
            <div className='mr-10'>
              <ul className='md:flex space-x-8 hidden '>
                <li>
                  <a
                    href='https://y24-io.gitbook.io/y24/'
                    rel='noreferrer'
                    target='_blank'
                    className='text-gray-800 font-bold'>
                    Documentation
                  </a>
                </li>
                <li>
                  <a
                    href='https://pancakeswap.finance/swap?outputCurrency=0x652000ba3c230d83279AEC84A49d41d706AFB0F1'
                    rel='noreferrer'
                    target='_blank'
                    className='text-gray-800 font-bold'>
                    <img
                      src='assets/cake.svg'
                      className='w-8 h-8 img-fluid'
                      alt=''
                    />
                  </a>
                </li>
              </ul>
            </div>

            <a
              href='https://app.y24.io/'
              target='_blank'
              rel='noreferrer'
              className='md:flex mt-10 w-48 hidden items-center space-x-3 font-para py-3 px-6 font-semibold i bg-gradient-to-br  from-[#57546C] to-[#F1F1F8] text-black rounded-lg justify-center m-auto  transform hover:scale-105 hover:shadow-lg cursor-pointer  overflow-hidden  hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out'>
              <button>Launch App</button>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M11 19l-7-7 7-7m8 14l-7-7 7-7'
                  />
                </svg>
              </span>
            </a>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
